/* =Base */
body {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: $black;
  background-color: $beige;
}

a {
  padding: 4px 4px 0 4px;
  &:link, &:visited {
    color: $lightblue;
    text-decoration: none;
  }
  &:hover, &:active {
    color: $beige;;
    background-color: $lightblue;
    text-decoration: none;
  }
}

a.img-link {
  background-color: transparent;
  display: inline-block;
  margin-left: 10px;
}

.logo {
  display: inline-block;
  margin: 25px auto;
}

p, ul, ol {
  font-size: 20px;
  line-height: 32px;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
  width: 85%;
  margin: 0 auto;
  padding: 15px;
  background-color: $lightgray;
  color: $black;
}

:not(pre) > code {
  font-family: monospace, monospace;
  font-size: 1em;
  background: $lightgray;
  padding: 4px;
}

// Make <code>...</code> text a little smaller, otherwise it looks too big
// compared to the sans serif we're using in <p>...</p>.
p > code {
  font-size: 0.8em;
}

img {
  margin: 40px auto;
  display: block;
  max-width: 100%;
}

blockquote {
  letter-spacing: 3px;
  margin:0px;
  margin-top: 40px;
  margin-bottom: 70px;
  padding: 0px;
  text-align: center;
  position: relative;

  footer {
    position: absolute;
    bottom: -35px;
    font-size: 15px;
    color: $black;
    letter-spacing: 0px;
    left:0px;
    right: 0px;

    &:before { content: "** "; }
    &:after { content: " **"; }
  }

  p {
    display: inline;
    font-size: 20px;
    color: $black;
    font-weight: 400;
    // &:before { content: "“ "; }
    // &:after { content: " ”"; }
  }
}

strong {
  color: $black;
}

.wrapper {
  max-width: 940px;
  margin: 10px auto;
}

.empty-title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;
  border-top: 1px solid $lightgray;
}

hr {
  border: 0;
  border-top: 1px solid $lightgray;
}

table {
  td {
    padding: 5px;
  }
}

.page-title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;
  padding: 10px 0;
  border-top: 1px solid $lightgray;
  border-bottom: 1px solid $lightgray;
}

.header {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.footer {
  text-align: center;
  margin-top: 6em;
  border-top: 1px solid $lightgray;
  padding: 2em 0;
  color: $mediumgray;
}

.navigation {
  margin-left: 20px;
  margin-top: -25px;
}

.menu {
  color: $lightgray;
  font-size: 18px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex; /* Use flexbox for better control */
  flex-wrap: wrap; /* Allow items to wrap */
}

.menu__entry {
  margin-right: 25px; /* Add some space between items */
  margin-bottom: 10px; /* Add some space between rows */
}

@media (max-width: 600px) {
  .menu {
    justify-content: flex-start; /* Align items at the start */
  }

  .menu__entry {
    flex-grow: 1; /* Allow items to grow to fill the available space */
    flex-shrink: 1; /* Allow items to shrink if needed */
    flex-basis: 0; /* Distribute remaining space equally among items */
    text-align: center; /* Center align text */
    white-space: nowrap; /* Prevent text from wrapping */
  }
}

.social-links {
  float:right;
  font-size: 24px;
  color: $lightgray;
  list-style-type: none;
}

.social-links__entry {
  display: inline-block;
  margin-left: 10px;
}

/* =Titles */
.page-title__text {
  font-weight: 700;
  font-size: 70px;
}

.page-title__subtitle {
  font-weight: 400;
  font-size: 22px;
  color: $lightgray;
}

.post-title {
  margin-bottom: 110px;
}

.post-title__text {
  font-size: 40px;
}

.post-title__subtitle {
  font-size: 20px;
}

/* =Post teasers */

.list-posts {
  list-style-type: none;
  padding: 0px;
}

.post-teaser {
  margin-bottom: 45px;
  font-weight: 700;
}

.post-teaser__title {
  font-size: 22px;
  color: $black;
}

.post-teaser__date {
  font-size: 16px;
  float: right;
  vertical-align: middle;
  color: $lightgray;
}

.empty-post-list {
  font-size: 22px;
  font-weight: 700;
  color: $black;
  text-align: center;
}

/* =Explore */
.explore {
  text-align: center;
  color: $lightgray;
  margin-top: 140px;
}

.explore__devider {
  font-size: 18px;
  letter-spacing: 8.18px;
}

.explore__label {
  font-size: 14px;
  margin-top: 15px;
}

.categories {
  list-style-type: none;
  margin-top: 15px;
  padding: 0px;
}

.categories__item {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
}

.categories__item a {
  color: $black;
}

.categories__item:after {
  content: "/";
  margin-left: 6px;
  color: $lightgray;
}

.categories__item:last-child:after {
  content: "";
  margin-left: 0px;
}


/* =Post page */
.about {
  color: $lightgray;
  margin-top: 70px;
  text-align: center;
  line-height: 24px;
}

.about__devider {
  font-size: 20px;
  margin-bottom: 50px;
  letter-spacing: 9.09px;
}

.about__text {
  font-size: 18px;
}

#disqus_thread {
  margin-top: 100px;
}

@media (max-width: 800px) {
  /* =Base */
  p {
    font-size: 16px;
    line-height: 1.6;
  }

  .wrapper {
    width: 80%;
  }

  .navigation,
  .social-links {
    @include centered-block();
  }

  .social-links {
    font-size: 30px;
    margin-top: 35px;
  }

  .page-title__text {
    font-size: 40px;
  }

  .page-title__subtitle {
    font-size: 18px;
  }

  .post-teaser__title {
    font-size: 18px;
    @include centered-block();
  }

  .post-teaser__date {
    font-size: 16px;
    margin-top: 10px;
    float: none;
    @include centered-block();
  }

  .explore {
    margin-top: 80px;
  }

  .post-title__text {
    font-size: 28px;
  }

  .post-title__subtitle {
    font-size: 18px;
  }
}
