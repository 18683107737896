@charset "utf-8";

/* =Colors */

$black: #000000;
$lightblue: #35c0ed;
$lightgreen: #9aeba6;
$lightgray: #e3e3e3;
$mediumgray: #909090;
$beige: #f5f5f5;

@import
    "mixins",
    "default",
    "404"
;
